nav {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $font-default / 2;
  border-bottom: 1px solid lighten($black, 70%);

  img {
    flex: 0 0 auto;
    cursor: pointer;
    max-height: $nav-height;
  }

  ul {
    flex: auto;
    list-style: none;
    margin: 0;
    margin-left: $font-default * 2;

    li {
      display: inline-block;

      a {
        padding: 0 1 * $font-default;
      }

      border-right: 1px solid lighten($black, 70%);

      &:last-child {
        border-right: none;
      }
    }
  }
}

/*
nav {


  ul {


    li {
      display: inline-block;
      margin-left: .5 * $font-default;
      margin-right: .5 * $font-default;
      vertical-align: middle;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}*/
