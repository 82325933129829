footer {
  display: flex;
  justify-content: space-between;
  padding-top: $font-default;
  color: lighten($black, 70%);
  border-top: 1px solid lighten($black, 70%);
  font-size: $font-small;

  > * {
    flex: auto;
  }

  > *:last-child {
    text-align: right;
  }
}