input, textarea, select, button {
  @extend %sans-serif;
  border-radius: .2 * $font-default;
  display: inline-block;
  padding: .5 * $font-default;
  border: 1px solid $gray;
}

label {
  display: inline-block;
  margin-right: $font-default / 2;
  font-style: italic;

  input[type="radio"], input[type="checkbox"]  {
    margin-right: $font-default / 2;
  }
}

@mixin btn($color: $gray) {
  border: $color;
  background-color: rgba($color, .3);
  cursor: pointer;
  display: inline;
  padding: .5 * $font-default 1.3 * $font-default;
  text-align: center;
  &:hover {
    background-color: rgba($color, .5);
    color: $black;
  }

  &[disabled] {
    background: darken($white, 10);
    color: darken($gray, 10);
    cursor: not-allowed;
  }
}

input[type="submit"], input[type="reset"], button {
  @include btn();

  @each $class, $name in $colors {
    &.btn-#{$class} {
      @include btn($name);

      @each $iconName, $icon in $icons {
        &-#{$iconName} {
          @extend .btn-#{$class};
          @include icon($icon);
        }
      }
    }
  }

  //@each $class, $name in $colors {
  //  &.btn-#{$class} {
  //    @include btn($name);
  //
  //    @each $name, $icon in $icons {
  //      &-#{$name} {
  //        @include icon($icon);
  //      }
  //    }
  //  }
  //}
}

