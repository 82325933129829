a {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

hr {
  border-bottom: 1px solid lighten($black, 70%);
}

small {
  font-size: $font-small;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: 700;
}

del, s {
  text-decoration: line-through;
}

ins, u {
  text-decoration: underline;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}
