h1, h2, h3, h4, h5, h6 {
  margin-bottom: $font-default;
}

h1 {
  font-size: 2 * $font-default;
  font-weight: 500;
  line-height: 2.4 * $font-default;
  margin-top: 1 * $font-default;
}

h2 {
  font-size: 1.4 * $font-default;
  font-weight: 500;
  line-height: 1.9 * $font-default;
  margin-top: .7 * $font-default;
}

h3 {
  font-size: 1.2 * $font-default;
  font-weight: 500;
  line-height: 1.5 * $font-default;
  margin-top: .5 * $font-default;
}

h4 {
  font-size: $font-default;
  font-weight: 500;
  line-height: 1.3 * $font-default;
}

h5 {
  font-size: .8 * $font-default;
  font-weight: 500;
  line-height: $font-default;
}

h6 {
  font-size: .8 * $font-default;
  font-weight: 500;
  line-height: $font-default;
}
