@mixin icon($symbol: "\f015", $size : 1, $float: none, $color: $black ) {
  &::before {
    content: $symbol;
    font-family: 'Font Awesome 5 Free';
    font-size: $font-default * $size;
    color: $color;
    font-weight: bold;
    font-style: normal;
    float: $float;
    @if ($float == right) {
      margin-left: $font-default / 2;
    } @else {
      margin-right: $font-default / 2;
    }
  }
}