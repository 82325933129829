#lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  img {
    position: absolute;
    padding: $font-default;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    cursor: zoom-out;
    image-rendering: pixelated;
  }
}